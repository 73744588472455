<template>
  <div class="FileList">
    <div v-for="(item, index) in props.dataList" :key="index" class="FileItem">
      <div>
        <p @click="GoToDetails(item)">{{ item.title }}</p>
        <p>{{ item.info }}</p>
        <p>
          发布时间:<span>{{ item?.add_time }}</span>
          <!-- 阅读量:<span>{{ item.views }}</span> -->
        </p>
      </div>
      <div v-if="item.img">
        <el-image
          style="width: 200px; height: 120px"
          :src="item.img"
          class="cuImage"
          @click="GoToDetails(item)"
          fit="cover"
        >
          <template #error>
            <div class="image-slot">
              <span>加载中</span>
            </div>
          </template>
        </el-image>
      </div>
      <div v-else>
        <el-image
          style="width: 200px; height: 120px"
          :src="require('../../Home/img/logo.png')"
          class="cuImage"
          @click="GoToDetails(item)"
          fit="contain"
        >
          <template #error>
            <div class="image-slot">
              <span>加载中</span>
            </div>
          </template>
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
export default {
  name: "DataListComp",
  components: {},
  props: ["dataList"],
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({});
    const GoToDetails = (item) => {
      router.push({
        name: "CommerceDetails",
        query: {
          id: item.commerce_id,
        },
      });
    };
    return {
      state,
      props,
      GoToDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.FileList {
  margin-top: 20px;
  .FileItem {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d8d8d8;
    > div:nth-child(1) {
      width: calc(100% - 220px);
      //width: 100%;
      z-index: 222;
      p:nth-child(1) {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: #7f0404;
        }
      }
      p:nth-child(2) {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        //display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p:nth-child(3) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #505356;
        span {
          color: #7f0404;
          margin-left: 5px;
        }
        span:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
    > div:nth-child(2) {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}
</style>